import { httpBatchLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import superjson from "superjson";

import type { AppRouter } from "@workspace/api";
import { ENV_VARS } from "@workspace/app-config";

function getBaseUrl() {
  if (typeof window !== "undefined")
    // browser should use relative path
    return "";

  return `${ENV_VARS.NEXT_PUBLIC_APP_URL}`;
}

export const trpc = createTRPCNext<AppRouter>({
  // @ts-ignore
  config() {
    return {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            networkMode: "online",
          },
        },
      },
      transformer: superjson,
      links: [
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${getBaseUrl()}/api/trpc`,
          headers() {
            const apiToken = localStorage.getItem("apiToken");

            if (apiToken) {
              return {
                "X-FulToken": `Bearer ${apiToken}`,
              };
            } else {
              return {};
            }
          },
        }),
      ],
      /**
       * @link https://tanstack.com/query/v4/docs/reference/QueryClient
       **/
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});
