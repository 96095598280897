type AppEnv = "local" | "production" | "staging";

export const ENV_VARS = {
  PORT: process.env.PORT,
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: (process.env.APP_ENV as AppEnv) || "local",
  NEXT_PUBLIC_APP_ENV: (process.env.NEXT_PUBLIC_APP_ENV as AppEnv) || "local",
  NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,

  MYSQL_HOST: process.env.MYSQL_HOST,
  MYSQL_USER: process.env.MYSQL_USER,
  MYSQL_PASSWORD: process.env.MYSQL_PASSWORD ?? "fM}RptmzVy4Edi$YxB?xA+zo]FBz",
  MYSQL_DATABASE: process.env.MYSQL_DATABASE,
  MYSQL_TCP_PORT: process.env.MYSQL_TCP_PORT,

  REDIS_HOST: process.env.REDIS_HOST,
  REDIS_PORT: process.env.REDIS_PORT,
  REDIS_PASSWORD: process.env.REDIS_PASSWORD,

  NEXT_PUBLIC_SOKETI_APP_KEY: process.env.NEXT_PUBLIC_SOKETI_APP_KEY,
  NEXT_PUBLIC_SOKETI_HOST: process.env.NEXT_PUBLIC_SOKETI_HOST,
  NEXT_PUBLIC_SOKETI_PORT: process.env.NEXT_PUBLIC_SOKETI_PORT,
  SOKETI_APP_ID: process.env.SOKETI_APP_ID,
  SOKETI_APP_KEY: process.env.SOKETI_APP_KEY,
  SOKETI_HOST: process.env.SOKETI_HOST,
  SOKETI_PORT: process.env.SOKETI_PORT,
  SOKETI_APP_SECRET: process.env.SOKETI_APP_SECRET,

  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_SERVER_API_KEY: process.env.GOOGLE_MAPS_SERVER_API_KEY,

  S3_UPLOAD_KEY: process.env.S3_UPLOAD_KEY,
  S3_UPLOAD_SECRET: process.env.S3_UPLOAD_SECRET,
  S3_UPLOAD_BUCKET: process.env.S3_UPLOAD_BUCKET,

  EMAIL_SERVER: process.env.EMAIL_SERVER,

  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,

  INTERNAL_API_URL: process.env.NEXT_PUBLIC_INTERNAL_API_URL,
  WEB_API_URL: process.env.INTERNAL_API_URL,
};

export const ALLOWED_SUPERADMINS = [
  "faisal.mohd@fulflld.com",
  "anik.shovan@fulflld.com",
  "anis.hoque@fulflld.com",
  "shah.arafat@fulflld.com",
  "jahir.raju@fulflld.com",
  "shohidul.bari@fulflld.com",
  "nabil.ashraf@fulflld.com",
  "levy.yakubov@fulflld.com",
  "fahad.asghar@fulflld.com",
  "admin@FULFLLD.com",
];

export const FEATURE_FLAGS = {
  external_assignee: {
    staging: true,
    production: false,
    local: true,
  },
};

export const secrets = {
  AXIOM: "xaat-d5ea3f16-8bdc-4b7d-ba54-17e8b48e3590",
};
